import {Breadcrumb} from "antd";
import {HomeOutlined} from '@ant-design/icons';

import './ArticlePage.css'
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import baseURL from "../API";
import ReactMarkdown from 'react-markdown';

const ArticlePage = () => {
    const params = useParams()
    const { article_id } = params
    const [article,setArticle] = useState({content:""})

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.post(`${baseURL}/getArticle/`,{id:article_id});
            console.log(response)
            setArticle(response.data)
        };
        fetchData();
    }, [article_id]);

    return (
        <div className="null_content">
            <div className="header-seat"></div>
            <div className="f_crumb">
                <Breadcrumb items={[
                    {
                        href: '/',
                        title: (
                            <>
                                <HomeOutlined/>
                                <span>首页</span>
                            </>
                        )
                    },
                    {
                        title: article.title
                    }
                ]} className="crumb"/>
            </div>
            <div className="f-content">
                <div className="page-content">
                    <article>
                        <div className="title-3">
                            <h1 className="main">{article.title}</h1>
                            <div className="date">{article.date}</div>
                        </div>
                        <div className="data">
                            <div className="text color-1">
                                <p>
                                    <strong>
                                        <span style={{
                                            fontSize: "12pt"
                                        }}>
                                            <i>{article.author}</i>
                                        </span>
                                    </strong>
                                </p>
                                <ReactMarkdown children={article.content} />
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    );
}

export default ArticlePage;