import {Breadcrumb} from "antd";
import {HomeOutlined} from "@ant-design/icons";
import './ContactPage.css'

const ContactPage = () => {
    return (
        <div className="null_content">
            <div className="header-seat"></div>
            <div className="f_crumb">
                <Breadcrumb items={[
                    {
                        href: '/',
                        title: (
                            <>
                                <HomeOutlined/>
                                <span>首页</span>
                            </>
                        )
                    },
                    {
                        title: "联系我们"
                    }
                ]} className="crumb"/>
            </div>
            <div className="f-content">
                <div className="page-content">

                </div>
            </div>
        </div>
    )
}

export default ContactPage