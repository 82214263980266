import React, {Component} from "react";
import Nav from 'react-bootstrap/Nav';

class NavListItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovered: false
        };
        this.handleHover = this.handleHover.bind(this)
    }


    handleHover(){
        this.setState(prevState => ({
            isHovered: !prevState.isHovered
        }));
    }

    render() {
        const btnClass = this.state.isHovered? "menu_item sublist_show":"menu_item";
        return (
            <Nav className={btnClass} onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}>
                <Nav.Link className="txt" href={this.props.href}>{this.props.content}</Nav.Link>
            </Nav>
        )
    }
}

export default NavListItem