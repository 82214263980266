import {Component} from "react";
import './FooterMod.css'
import {NavLink} from "react-router-dom";

class FooterMod extends Component {
    render() {
        return (
            <div>
                <div className="join_us">
                    <div className="tit_area">
                        <h3 className="tit">加入我们</h3>
                        <i></i>
                    </div>
                    <ul className="footer_list_item">
                        <li>
                            <NavLink to="/">校园招聘</NavLink>
                        </li>
                        <li>
                            <NavLink to="/">社会招聘</NavLink>
                        </li>
                    </ul>
                </div>
                <div className="contact_us">
                    <div className="tit_area">
                        <h3 className="tit">联系我们</h3>
                        <i></i>
                    </div>
                    <ul className="footer_list_item">
                        <li>
                            <NavLink to="/">合作洽谈</NavLink>
                        </li>
                        <li>
                            <NavLink to="/">客户服务</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default FooterMod