import {Card, Col, Row} from "antd";
import {Component} from "react";
import axios from "axios";
import baseURL from "../API";
import {NavLink} from "react-router-dom";

const {Meta} = Card;

class CardNews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cards: [{key: 1, title: "", subtitle: ""}]
        }
    }

    componentDidMount() {
        axios({method: "GET", url: `${baseURL}/getCardList/`}).then(response => {
            this.setState({
                cards: response.data
            })
        })
    }

    render() {
        const backgroud_color = ["#fbfcff", "#f3f9ff", "#f4f6ff"]
        const width_array = [16,8,8,8,8,8,16]
        return (
            <Row gutter="24">
                {this.state.cards.map((item, index) => (
                    <Col span={width_array[index%7]} className="card" key={index}>
                        <NavLink to={"/article/" + item.href}>
                            <Card hoverable cover={<img src={item.pic} alt={item.name}/>}
                                  actionsbg={backgroud_color[Math.floor(Math.random() * 2)]}>
                                <Meta title={item.title} description={item.subtitle}/>
                            </Card>
                        </NavLink>
                    </Col>
                ))}
            </Row>
        )
    }
}

export default CardNews;