import logo from "../static/image/logo.png"
import './FooterLogo.css'

function FooterLogo(){
    return (
        <div className="logo_yunying">
            <img src={logo} alt="Yunying云影"/>
        </div>
    )
}

export default FooterLogo;