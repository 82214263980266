const BusinessCard = () => {
    return (
        <div className="f-in">
            <div className="first-pc">
                <div className="first-box">
                    <a href="http://www.chinasofti.com/rlzyjjfa/index.htm" className="first-sub first-icon1">
                        <img src="//www.chinasofti.com/u/cms/www/201910/09141737fita.jpg" alt="business1"/>
                        <div className="first-bg"></div>
                        <div className="first-text">
                            <div className="first-icon">
                                <img src="//www.chinasofti.com/u/cms/www/201910/091645511k3q.png" alt="business1"/>
                            </div>
                            <h2>人力资源解决方案</h2>
                            <div className="first-p">
                                <p>

                                </p>
                                <span>
                                                了解更多<i
                                    className="iconfont iconbaseline-keyboard_backspace-px-copy"></i>
                                            </span>
                            </div>
                        </div>
                    </a>


                    <a href="http://www.chinasofti.com/dsj/index.htm" className="first-sub first-icon2">
                        <img src="//www.chinasofti.com/u/cms/www/201910/30152936nv46.png" alt="business2"/>
                        <div className="first-bg"></div>
                        <div className="first-text">
                            <div className="first-icon">
                                <img src="//www.chinasofti.com/u/cms/www/201910/081917489lq3.png" alt="business2"/>
                            </div>
                            <h2>大数据分析与处理</h2>
                            <div className="first-p">
                                <p>慧聚数智 合创生态 人工智能时代的数据工程家</p>
                                <span>
                                                了解更多<i
                                    className="iconfont iconbaseline-keyboard_backspace-px-copy"></i>
                                            </span>
                            </div>
                        </div>
                    </a>

                    <a href="http://www.chinasofti.com/cloudService/index.htm" className="first-sub first-icon3">
                        <img src="//www.chinasofti.com/u/cms/www/201908/211533278ksn.jpg" alt="business3"/>
                        <div className="first-bg"></div>
                        <div className="first-text">
                            <div className="first-icon">
                                <img src="//www.chinasofti.com/u/cms/www/201908/29175331d083.png" alt="business3"/>
                            </div>
                            <h2>云计算</h2>
                            <div className="first-p">
                                <p>
                                    中软国际基于近20年来累积的各行业解决方案和成熟的服务经验，深入剖析不同行业的需求特征... </p>
                                <span>
                                                了解更多<i
                                    className="iconfont iconbaseline-keyboard_backspace-px-copy"></i>
                                            </span>
                            </div>
                        </div>
                    </a>

                    <a href="http://www.chinasofti.com/cloudService/index.htm" className="first-sub first-icon4">
                        <img src="//www.chinasofti.com/u/cms/www/201908/21153530vbzv.jpg" alt="business4"/>
                        <div className="first-bg"></div>
                        <div className="first-text">
                            <div className="first-icon">
                                <img src="//www.chinasofti.com/u/cms/www/201910/08183037i9i4.png" alt="business4"/>
                            </div>
                            <h2>CloudEasy云管家</h2>
                            <div className="first-p">
                                <p>
                                    中软国际云管理服务为企业提供以云迁移、云监控运维等为主的一站式上云服务 </p>
                                <span>
                                                了解更多<i
                                    className="iconfont iconbaseline-keyboard_backspace-px-copy"></i>
                                            </span>
                            </div>
                        </div>
                    </a>

                    <a href="http://www.chinasofti.com/consultingServices/index.htm" className="first-sub first-icon5">
                        <img src="//www.chinasofti.com/u/cms/www/201908/21153801qiid.jpg" alt="business5"/>
                        <div className="first-bg"></div>
                        <div className="first-text">
                            <div className="first-icon">
                                <img src="//www.chinasofti.com/u/cms/www/201908/29175016sia9.png" alt="business5"/>
                            </div>
                            <h2>咨询服务</h2>
                            <div className="first-p">
                                <p>中软国际咨询服务团队，长期与客户保持共同协作，积累了丰富的行业经验</p>
                                <span>
                                                了解更多<i
                                    className="iconfont iconbaseline-keyboard_backspace-px-copy"></i>
                                            </span>
                            </div>
                        </div>
                    </a>

                    <a href="http://www.chinasofti.com/development/index.htm" className="first-sub first-icon6">
                        <img src="//www.chinasofti.com/u/cms/www/201908/21153852xy40.jpg" alt="business6"/>
                        <div className="first-bg"></div>
                        <div className="first-text">
                            <div className="first-icon">
                                <img src="//www.chinasofti.com/u/cms/www/201908/29175030uivd.png" alt="business6"/>
                            </div>
                            <h2>应用开发</h2>
                            <div className="first-p">
                                <p>中软国际拥有专业化的应用开发服务团队，长期为客户世界500强客户提供端到端的应用软件开...</p>
                                <span>
                                                了解更多<i
                                    className="iconfont iconbaseline-keyboard_backspace-px-copy"></i>
                                            </span>
                            </div>
                        </div>
                    </a>

                    <a href="http://www.chinasofti.com/mservices/index.htm" className="first-sub first-icon6">
                        <img src="//www.chinasofti.com/u/cms/www/201908/211539433e3u.jpg" alt="business7"/>
                        <div className="first-bg"></div>
                        <div className="first-text">
                            <div className="first-icon">
                                <img src="//www.chinasofti.com/u/cms/www/201908/29175044xjvo.png" alt="business7"/>
                            </div>
                            <h2>管理服务</h2>
                            <div className="first-p">
                                <p>中软国际拥有国际化的管理服务团队，其中，团队管理人员大多具备20年以上的IT行业经验以及...</p>
                                <span>
                                                了解更多<i
                                    className="iconfont iconbaseline-keyboard_backspace-px-copy"></i>
                                            </span>
                            </div>
                        </div>
                    </a>

                    <a href="http://www.chinasofti.com/product/index.htm" className="first-sub first-icon6">
                        <img src="//www.chinasofti.com/u/cms/www/201908/211540377x4x.jpg" alt="business8"/>
                        <div className="first-bg"></div>
                        <div className="first-text">
                            <div className="first-icon">
                                <img src="//www.chinasofti.com/u/cms/www/201908/29175233j3j4.png" alt="business8"/>
                            </div>
                            <h2>产品工程</h2>
                            <div className="first-p">
                                <p>中软国际具有卓越的产品工程服务、交付能力，从产品设计、产品开发到后期的质量测试与保证</p>
                                <span>
                                                了解更多<i
                                    className="iconfont iconbaseline-keyboard_backspace-px-copy"></i>
                                            </span>
                            </div>
                        </div>
                    </a>

                    <a href="http://www.chinasofti.com/testing/index.htm" className="first-sub first-icon7">
                        <img src="//www.chinasofti.com/u/cms/www/201910/30161538ruga.jpg" alt="business9"/>
                        <div className="first-bg"></div>
                        <div className="first-text">
                            <div className="first-icon">
                                <img src="//www.chinasofti.com/u/cms/www/201908/29175246b6l7.png" alt="business9"/>
                            </div>
                            <h2>测试服务</h2>
                            <div className="first-p">
                                <p>
                                    中软国际拥有成熟专业的测试方法论和先进完善的测试工具，结合分布全球的开发和能力中心，... </p>
                                <span>
                                                了解更多<i
                                    className="iconfont iconbaseline-keyboard_backspace-px-copy"></i>
                                            </span>
                            </div>
                        </div>
                    </a>

                    <a href="http://www.chinasofti.com/software/index.htm" className="first-sub first-icon8">
                        <img src="//www.chinasofti.com/u/cms/www/201908/21154236i375.jpg" alt="business10"/>
                        <div className="first-bg"></div>
                        <div className="first-text">
                            <div className="first-icon">
                                <img src="//www.chinasofti.com/u/cms/www/201908/29175258nx25.png" alt="business10"/>
                            </div>
                            <h2>软件平台</h2>
                            <div className="first-p">
                                <p>中软国际一直致力于平台及中间件产品的研发工作。目前，中软国际已拥有三大系列完全自主知...</p>
                                <span>
                                                了解更多<i
                                    className="iconfont iconbaseline-keyboard_backspace-px-copy"></i>
                                            </span>
                            </div>
                        </div>
                    </a>

                    <a href="http://www.chinasofti.com/outsourcing/index.htm" className="first-sub first-icon9">
                        <img src="//www.chinasofti.com/u/cms/www/201908/21154336kq7s.jpg" alt="business11"/>
                        <div className="first-bg"></div>
                        <div className="first-text">
                            <div className="first-icon">
                                <img src="//www.chinasofti.com/u/cms/www/201908/29175317o26a.png" alt="business11"/>
                            </div>
                            <h2>业务流程外包</h2>
                            <div className="first-p">
                                <p>
                                    中软国际在业务流程外包方面有着长达26年的服务经验，在业务分析、流程设计、系统开发、构... </p>
                                <span>
                                                了解更多<i
                                    className="iconfont iconbaseline-keyboard_backspace-px-copy"></i>
                                            </span>
                            </div>
                        </div>
                    </a>

                </div>
            </div>
        </div>
    )

}

export default BusinessCard;