import React, { useState, useEffect } from 'react';
import './NavBar.css';
import NavListItem from './NavListItem';
import { NavLink, useLocation } from 'react-router-dom';
import withRouter from '../withRouter';
import {Navbar} from "react-bootstrap";

const NavBarCustom = ({ config }) => {
    const location = useLocation();
    const [state, setState] = useState({
        height: 0,
        white: false,
        hide: false,
        index: true,
    });

    const WINDOWS_HEIGHT = window.innerHeight;

    useEffect(() => {
        if(location.pathname === "/"){
            setState({
                white: false,
                index: true
            })
        }
        else {
            setState({
                white: true,
                index: false
            })
        }
    },[location.pathname])

    useEffect(() => {
        const bindScroll = () => {
            setState((prevState) => {
                const newHeight = window.scrollY;
                let newWhite = prevState.white;
                let newHide = prevState.hide;

                if (prevState.index) {
                    if (newHeight > WINDOWS_HEIGHT * 0.3 && newHeight < WINDOWS_HEIGHT * 0.8) {
                        newWhite = true;
                        newHide = false;
                    } else if (newHeight > WINDOWS_HEIGHT * 0.8) {
                        newWhite = true;
                        newHide = true;
                    } else {
                        newWhite = false;
                        newHide = false;
                    }
                } else {
                    if (newHeight > WINDOWS_HEIGHT * 0.1) {
                        newWhite = true;
                        newHide = true;
                    } else {
                        newWhite = true;
                        newHide = false;
                    }
                }

                return {
                    ...prevState,
                    height: newHeight,
                    white: newWhite,
                    hide: newHide,
                };
            });
        };

        window.addEventListener('scroll', bindScroll);

        return () => {
            window.removeEventListener('scroll', bindScroll);
        };
    }, [WINDOWS_HEIGHT, state.index]);

    const handleMouseEnter = () => {
        if (state.index) {
            setState((prevState) => ({
                ...prevState,
                white: true,
            }));
        }
    };

    const handleMouseOut = () => {
        if (state.index) {
            setState((prevState) => ({
                ...prevState,
                white: false,
            }));
        }
    };

    return (
        <Navbar expand="lg" className={`bg-body-tertiary wrap_head ${state.white ? 'white' : ''} ${state.hide ? 'hide' : ''} ${!state.index ? 'index' : ''}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseOut}>
                <div className="NavBar">
                    <Navbar.Brand>
                        <NavLink className="logo" to="/">
                            <h1>Yunying</h1>
                        </NavLink>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="menu_toggle" >
                        <i className="i_menu"></i>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav" className="menu_list">
                        {config.map((item, index) => (
                            <NavListItem content={item.name} href={item.href} key={index} />
                        ))}
                    </Navbar.Collapse>
                </div>
        </Navbar>
    );
};

export default withRouter(NavBarCustom);
