import { Swiper, SwiperSlide } from 'swiper/react';
import {Component} from "react";
import 'swiper/css';
import './MainBanner.css'
import axios from "axios";
import baseURL from "../API";
import './BannerNav.css'
import {Autoplay,Navigation,Pagination} from "swiper/modules";
import 'swiper/css/autoplay'
import {NavLink} from "react-router-dom";

class MainBanner extends Component{

    constructor(props) {
        super(props);
        this.state = {
            banner: [{key:1,title:"",subtitle:""}],
            role: props.role === undefined? 0:props.role
        }

    }
    componentDidMount() {
        axios({method: "GET",url:`${baseURL}/getBannerList/?role=${this.state.role}`}).then(response => {
            this.setState({
                banner: response.data
            })
        })
    }

    render(){
        return(
            <section className="banner">
            <Swiper className="yy-swiper-wrapper swiper-container" modules={[Autoplay,Navigation,Pagination]} loop={true} autoplay={{delay: 3000}} navigation={{ prevEl: '.banner_arr_l', nextEl: '.banner_arr_r' }} pagination={{
                el: ".banner_bullet"
            }}>
                {this.state.banner.map((item,index) => (
                    <SwiperSlide className="banner_slide" key={index}>
                        <NavLink to={"/article/" + item.href}>
                            <img className="banner_img" src={item.pic} alt={item.name}/>
                            <div className="banner_shadow"></div>
                            <div className="banner_text">
                                <div className="yy_main">
                                    <h2>{item.title}</h2>
                                    <h5>{item.subtitle}</h5>
                                </div>
                            </div>
                        </NavLink>
                    </SwiperSlide>
                ))}
                <div className={this.state.role === 0? "banner-nav" : "banner-nav bottom"}>
                    <div className={"main " + (this.state.role === 0? "line" : "")}>
                        {this.state.role === 0? (
                            <div className="banner_arr">
                                <span className="banner_arr_l"></span>
                                <span className="banner_arr_r"></span>
                            </div>
                        ):null}
                        <div className="banner_bullet">

                        </div>
                    </div>
                </div>
            </Swiper>
            </section>
        )
    }
}

export default MainBanner;