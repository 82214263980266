import './App.css';
import {Component} from "react";
import NavBar from "./components/NavBarCustom";
import FooterLogo from "./components/FooterLogo";
import FooterLinks from "./components/FooterLinks";
import CookieBox from "./components/CookieBox";
import cookie from 'react-cookies'
import FooterFocus from "./components/FooterFocus";
import {Affix} from "antd";
import FooterMod from "./components/FooterMod";
import { Routes, Route } from "react-router-dom";
import IndexPage from "./Page/IndexPage";
import ArticlePage from "./Page/ArticlePage";
import ContactPage from "./Page/ContactPage";
import BusinessPage from "./Page/BusinessPage";


// eslint-disable-next-line no-undef
class App extends Component {
    constructor(props) {
        super(props);
        const FooterLinksConfig = [{name: "联系我们", href: "contact"}, {
            name: "网站地图",
            href: "sitemap"
        }, {name: "陕ICP备2022005333号-1", href: "https://beian.miit.gov.cn/"}]
        const NavBarItemList = [{name: "简介", href: "instruction"}, {name: "业务", href: "business"}, {
            name: "关于",
            href: "about"
        },{
            name: "Forum",
            href: "//forum.xayytech.com"
        }]
        FooterLinksConfig.forEach((value, index) => {
            value['key'] = index
        })
        NavBarItemList.forEach((value, index) => {
            value['key'] = index
        })
        this.state = {
            footerLinksConfig: FooterLinksConfig,
            navBarItemList: NavBarItemList,
            acceptCookie: cookie.load('acceptCookie')
        }
    }


    render() {
        return (
            <div className="wrap">
                {this.state.acceptCookie === undefined ? <CookieBox/> : null}
                <NavBar config={this.state.navBarItemList}/>
                <Routes>
                    <Route path="/" element={<IndexPage />}></Route>
                    <Route path="/article/:article_id" element={<ArticlePage />}></Route>
                    <Route path="/contact" element={<ContactPage />}></Route>
                    <Route path="/business" element={<BusinessPage />}></Route>
                </Routes>


                <div className="wrap_footer">
                    <Affix style={{
                        position: "absolute", right: "30px"
                    }} offsetTop={window.innerHeight * 0.9} onChange={() => {
                        console.log("1")
                    }}>
                        <div className="return-top" onClick={() =>{
                            const scrollToTop = window.setInterval(() => {
                                let pos = window.pageYOffset;
                                if ( pos > 0 ) {
                                    window.scrollTo( 0, pos - 100 );
                                } else {
                                    window.clearInterval( scrollToTop );
                                }
                            }, 1);
                        }}>
                            <b>↑</b>
                        </div>
                    </Affix>
                    <div className="footer">
                        <FooterFocus/>
                        <FooterMod/>
                        <FooterLogo/>
                        <FooterLinks config={this.state.footerLinksConfig}/>
                    </div>
                </div>
            </div>
        );
    }

}

export default App;
