import {Component} from "react";
import './CookieBox.css'
import cookie from 'react-cookies'

class CookieBox extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isShow: true
        }
    }
    acceptCookies=()=>{
        cookie.save("acceptCookie",true)
        this.setState({
            isShow:false
        })

    }
    rejectCookies=()=>{
        cookie.save("acceptCookie",false)
        this.setState({
            isShow:false
        })
    }
    render() {
        return (
            this.state.isShow? <div className="cookie-box">
                    <div className="box-top">
                        <div className="content-box title">请选择您的Cookies偏好</div>
                        <div className="content-box">欢迎来到xayytech.com！</div>
                        <div className="content-box">我们希望在本网站上使用Cookies，以便保障本网站的安全、高效运转及服务优化，有关我们使用cookie的更多信息，请点击查看了解更多。</div>
                    </div>
                    <div className="btn-box">
                        <div className="accept-btn" onClick={this.acceptCookies}>接受Cookies</div>
                        <div className="reject-btn" onClick={this.rejectCookies}>拒绝Cookies</div>
                    </div>
                </div>:null
        );
    }
}

export default CookieBox