import './BusinessPage.css'
import BusinessCard from "../components/BusinessCard";

const BusinessPage = () => {


    return (
        <div className="null_content">
            <div className="header-seat"></div>
            <div className="wrap_content business-user">
                <div className="mod-section-ad">
                    <div className="mod-con clearfix">
                        <h2>融合创新</h2>
                        <p className="desc">从智能人机互联、安全服务到全球物联网格局，构筑共生共赢的未来科技生态</p>
                    </div>
                </div>
                <div className="mod-tab-wrap">
                    <div className="mod-tab-content">
                        <div className="mod-max-con clearfix">
                            <div className="section-item section-one-item white-bg clearfix">
                                <div className="mod-s-con">
                                    <BusinessCard />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BusinessPage