import './FooterLinks.css'
import {Component} from "react";
import {NavLink} from "react-router-dom";

class FooterLinks extends Component{
    constructor(props) {
        super(props);
        this.state = {
            config: this.props.config
        };
    }
    render() {
        return (
                <div className="footer_area">
                    <ul className="links">
                        {this.state.config.map((item,index) => (
                            <li key={index}>
                                <NavLink to={item.href}>{item.name}</NavLink>
                            </li>
                        ))}
                    </ul>
                    <p className="copyright">
                        Copyright © 2022 - 2024 Yunying. All Rights Reserved. 云影科技 版权所有
                    </p>
                </div>
        )
    }
}

export default FooterLinks