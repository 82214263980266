import MainBanner from "../components/MainBanner";
import CardNews from "../components/CardNews";

import {Component} from "react";


class IndexPage extends Component{
    render(){
        return (
            <div className="whats_new">
                <MainBanner/>
                <section className="cards">
                    <div className="yy_main">
                        <h4>最新动态</h4>
                        <CardNews/>
                    </div>
                </section>
                <MainBanner role={1}/>
            </div>
        )
    }
}

export default IndexPage